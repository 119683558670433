/* eslint-disable arrow-body-style */
import { Api } from '@/services/api'

export const getAllGoalNames = async (token) => Api(token).get('/V1/Goal/GetAllGoals')

export const getAllGoals = async (token) => Api(token).get('/V1/CustomerGoal/GetAllGoals')

export const getGoalById = async (token, { goalId = null }) => {
	if (!goalId) return undefined
	return Api(token).get(`/V1/CustomerGoal/GetCustomerGoal?id=${goalId}`)
}

export const createOrUpdateGoal = async (token, { formData = {} }) => {
	return Api(token).post('/V1/CustomerGoal/SaveGoal', formData)
}

export const completeGoal = async (token, { goalId = null }) => {
	return Api(token).post('/V1/CustomerGoal/CompleteGoal/', { Id: goalId })
}

export const deleteGoal = async (token, { goalId = null }) => {
	return Api(token).post('/V1/CustomerGoal/DeleteGoal/', { Id: goalId })
}

export default undefined
