<template>
	<div>
		<div
			v-if="isGoalDetailsLoading"
			class="d-flex align-center justify-center min-height-screen w-full h-full"
		>
			<loader />
		</div>
		<!-- products -->
		<v-card v-else elevation="0" class="h-full min-height-screen pb-2">
			<v-toolbar flat class="border-b-2 border--secondary px-0 py-3 !h-85px">
				<v-toolbar-title class="text-capitalize">
					<a :href="`${baseUrl}/goals?token=${authToken}`" class="text-decoration-none mr-2">
						<v-icon color="primary">mdi-arrow-left</v-icon>
					</a>
					<strong>{{ goal.goalName }}</strong>
				</v-toolbar-title>

				<v-spacer></v-spacer>

				<v-btn
					icon
					@click="
						showSearchBar = !showSearchBar
						searchTerm = ''
					"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</v-toolbar>

			<!-- seacrh bar -->
			<transition name="fade">
				<v-form v-if="showSearchBar" @submit.prevent="">
					<v-text-field
						outlined
						hide-details
						v-model.trim="searchTerm"
						placeholder="Search products..."
					/>
				</v-form>
			</transition>

			<div class="w-full h-full pt-2">
				<!-- show products -->
				<div
					v-if="isGoalProductsLoading"
					class="d-flex align-center justify-center w-full h-full"
					style="min-height: 80vh"
				>
					<loader />
				</div>
				<div v-else class="h-full">
					<div v-if="hasGoalProducts" class="h-full">
						<a
							v-for="product in goalProducts"
							:key="product.id"
							:href="`${baseUrl}/product/${product.id}?token=${authToken}`"
							class="text-decoration-none"
							ref="productsListRef"
						>
							<product-card :product="product" />
						</a>

						<!-- show loading more spinner -->
						<transition name="fade" mode="out-in">
							<div
								v-if="isMoreGoalProductsLoading"
								class="d-flex align-center justify-center w-full h-full mt-6"
							>
								<loader />
							</div>
						</transition>
					</div>
					<!-- no products -->
					<div
						v-else-if="hasNoGoalProducts"
						class="d-flex align-center justify-center w-full h-full mt-6"
					>
						<h3>No products found</h3>
					</div>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
/* eslint-disable comma-dangle */
import debounceFn from 'debounce-fn'
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import ProductCard from '@/components/ProductCard.vue'
import { getGoalById } from '@/services/goal'
import { getProductsByGoalId } from '@/services/product-goal'
import { SUCCESS, NOT_FOUND } from '@/constants/status-code'

const PAGE_SIZE = 10

export default {
	name: 'ProductsGoalDetails',
	mixins: [AuthMixin],
	components: { Loader, ProductCard },
	data() {
		return {
			goalId: this.$route.params.id,
			isGoalDetailsLoading: false,
			isGoalProductsLoading: false,
			isMoreGoalProductsLoading: false,
			showSearchBar: false,
			goal: null,
			goalProducts: [],
			searchTerm: '',
			pageNo: 1,
			observer: null,
			productListLastElementRef: null,
		}
	},
	created() {
		this.fetchGoalById()
	},
	mounted() {
		this.$watch(
			() => this.searchTerm,
			debounceFn(
				() => {
					this.pageNo = 1
					this.fetchProductsOfGoal()
				},
				{ wait: 500 }
			)
		)
	},
	computed: {
		hasNoGoalProducts() {
			return !this.isGoalProductsLoading && !this.hasGoalProducts
		},
		hasGoalProducts() {
			return Array.isArray(this.goalProducts) && this.goalProducts.length > 0
		},
	},
	methods: {
		isIntersecting(entries) {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					this.pageNo += this.pageNo
					this.fetchProductsOfGoal({ fetchNext: true })
				}
			})
		},
		addOrRemoveObeserver(onlyRemoveObserver = false) {
			if (
				// eslint-disable-next-line operator-linebreak
				this.observer instanceof IntersectionObserver &&
				this.productListLastElementRef
			) {
				this.observer.unobserve(this.productListLastElementRef)
				this.observer = null
			}

			if (onlyRemoveObserver) return

			console.log(this.$refs.productsListRef)

			// eslint-disable-next-line operator-linebreak
			this.productListLastElementRef =
				this.$refs.productsListRef[this.$refs.productsListRef.length - 1]

			this.observer = new IntersectionObserver(this.isIntersecting)
			this.observer.observe(this.productListLastElementRef)
		},
		async fetchGoalById() {
			this.isGoalDetailsLoading = true
			try {
				const res = await getGoalById(this.authToken, { goalId: this.goalId })
				const { data, statusCode } = res.data

				if (statusCode === SUCCESS) {
					this.goal = data
					this.isGoalDetailsLoading = false
					this.fetchProductsOfGoal()
				}
			} catch (e) {
				console.log(e, 'error')
			}
			this.isGoalDetailsLoading = false
		},
		async fetchProductsOfGoal(args = {}) {
			const { fetchNext } = args

			const params = {
				goalId: this.goal.goalId,
				searchText: this.searchTerm,
				pageNo: this.pageNo,
				pageSize: PAGE_SIZE,
			}

			if (fetchNext) {
				this.isMoreGoalProductsLoading = true
			} else {
				this.isGoalProductsLoading = true
			}

			try {
				const res = await getProductsByGoalId(this.authToken, { params })
				const { data, statusCode } = res.data

				if (statusCode === NOT_FOUND) {
					this.goalProducts = []
				}

				if (statusCode === SUCCESS) {
					const { totalPage, result: products } = data
					const hasNoMoreProducts = products.length > 0
					const removeObserver = !hasNoMoreProducts

					this.goalProducts = fetchNext ? [...this.goalProducts, ...products] : products
					// dont add observer if products has only 1 or less page
					if (totalPage > 1) {
						this.$nextTick().then(() => {
							this.addOrRemoveObeserver(removeObserver)
						})
					}
				}
			} catch (e) {
				console.log(e, 'error')
			}

			if (fetchNext) {
				this.isMoreGoalProductsLoading = false
			} else {
				this.isGoalProductsLoading = false
			}
		},
	},
}
</script>
