<template>
	<v-card flat elevation="0" class="px-3">
		<div class="d-flex flex-no-wrap align-start justify-space-between">
			<div class="my-3 pa-3 border-2 border--secondary rounded-small">
				<v-avatar size="84" tile>
					<v-img class="rounded" :lazy-src="productImageUrl" :src="productImageUrl" />
				</v-avatar>
			</div>
			<div class="py-3 pl-3 flex-grow-1">
				<h3 class="text-base mb-1 font-weight-bold truncate-text-1">{{ product.name }}</h3>

				<div
					class="text--secondary mb-1 text-sm truncate-text-3"
					v-html="product.description"
				></div>
				<div class="d-flex align-center flex-wrap">
					<p class="primary--text font-weight-bold text-base mb-0 mr-2">
						{{ currencyFormatter.format(finalProductPrice) }}
					</p>
					<span
						v-if="doesProductHaveDiscount"
						class="text-decoration-line-through text--secondary text-xs"
						>{{ currencyFormatter.format(product.unitCost) }}</span
					>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import currencyFormatter from '@/utils/currency-formatter'
import { IMAGE_BASE_URL } from '@/constants'

export default {
	name: 'ProductCard',
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			currencyFormatter,
		}
	},
	computed: {
		doesProductHaveDiscount() {
			return this.product.dealPrice !== null && this.product.dealPrice > 0
		},
		finalProductPrice() {
			if (this.doesProductHaveDiscount) return this.product.dealPrice

			return this.product.unitCost
		},
		productImageUrl() {
			if (!this.product.imageUrl) return '/placeholder-image.png'

			if (this.product.imageUrl.startsWith('http')) return this.product.imageUrl

			return `${IMAGE_BASE_URL}/${this.product.imageUrl}`
		},
	},
}
</script>
